import { Component } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
    selector: 'app-expired',
    templateUrl: './expired.component.html',
    styleUrls: ['./expired.component.scss'],
    standalone: false
})
export class ExpiredComponent {
  options: AnimationOptions = {
    path: '/assets/lottie/qr.json'
  };
}
