import { catchError, switchMap } from 'rxjs/operators';
import { Injectable, inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  auth = inject(AuthService);
  router = inject(Router);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const language: string = localStorage.getItem('language') || 'it';

    request = request.clone({
      headers: request.headers.set('Content-Language', language)
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (this.router.url.includes('menu')) {
            window.location.href = '/expired';
            return EMPTY;
          }

          return this.auth.refreshToken().pipe(
            switchMap(() => {
              const clonedRequest = request.clone({
                headers: request.headers.set('Authorization', `Bearer ${this.auth.getAccessToken()}`)
              });
              return next.handle(clonedRequest);
            }),
            catchError(() => {
              this.auth.resetToken();
              window.location.href = '/error';
              return EMPTY;
            })
          );
        }
        return throwError(() => error);
      })
    );
  }
}
