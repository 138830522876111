import { SharedModule } from 'src/app/shared/shared.module';
import { environment } from './../environments/environment';
import { RouteReuseService } from './core/services/router-reuse.service';
import { NgModule, LOCALE_ID } from '@angular/core';
import localeIt from '@angular/common/locales/it';
import { DatePipe, registerLocaleData, CurrencyPipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './features/home/home.component';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RouteReuseStrategy } from '@angular/router';

import { ServiceWorkerModule } from '@angular/service-worker';

import { PromptComponent } from './shared/components/prompt/prompt.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';

import { ExpiredComponent } from './features/errors/pages/expired/expired.component';
import { ErrorComponent } from './features/errors/pages/error/error.component';

registerLocaleData(localeIt);

export function tokenGetter() {
    return localStorage.getItem("access_token");
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        PromptComponent,
        ExpiredComponent,
        ErrorComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
        NgbModule,
        LottieComponent,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: ["localhost:44350", "visualorder.it"]
            }
        }),
        TranslateModule.forRoot({
            defaultLanguage: 'it',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        provideLottieOptions({
            player: () => import('lottie-web'),
        }),
        DatePipe,
        CurrencyPipe,
        { provide: LOCALE_ID, useValue: 'it' },
        { provide: RouteReuseStrategy, useClass: RouteReuseService },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
