import { Component, inject } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';

@Component({
    selector: 'app-prompt',
    templateUrl: './prompt.component.html',
    styleUrls: ['./prompt.component.scss'],
    standalone: false
})
export class PromptComponent {
  data = inject<{
    mobileType: 'ios' | 'android';
    promptEvent?: any;
}>(MAT_BOTTOM_SHEET_DATA);
  private bottomSheetRef = inject<MatBottomSheetRef<PromptComponent>>(MatBottomSheetRef);


  public installPwa(): void {
    this.data.promptEvent.prompt();
    this.close();
  }

  public close(): void {
    this.bottomSheetRef.dismiss();
  }
}
