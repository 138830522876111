import { ShopService } from '../../core/services/shop.service';
import { Component, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: false
})
export class HomeComponent implements OnInit {
  private negoziService = inject(ShopService);
  private titleService = inject(Title);

  shops: any[] = [];
  errore: string = '';

  constructor() {
    this.titleService.setTitle('VisualOrder');
  }

  generateSkeleton(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  ngOnInit(): void {
    this.negoziService.getAllShops().subscribe((shops) => {
      this.shops = shops;
    });
  }
}
