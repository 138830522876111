import { BusinessHours } from '../models/business-hours.model';
import { Injectable, inject } from '@angular/core';
import { map, retry, catchError, switchMap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, forkJoin, of, throwError } from 'rxjs';
import { ShopSetup } from '../models/shop-setup.model';
import { environment } from 'src/environments/environment';
import { ShopInfo } from '../models/shop-info.model';

interface ShopDay {
  id: number;
  giorno: Date;
  orari: Date[];
}

@Injectable({
  providedIn: 'root',
})
export class ShopService {
  private http = inject(HttpClient);

  public currentShop = new BehaviorSubject<any>(new ShopInfo());

  getAllShops(): Observable<any[]> {
    return this.http
      .get(`${environment.API_URL}/shops`)
      .pipe(map((data: any[]) => data), retry(3), catchError((error: HttpErrorResponse) => {
        if (error.error instanceof Error) {
          return throwError('Errore generico');
        } else {
          return throwError('Errore durante la connessione al server');
        }
      }));
  }

  getShop(idNegozio: string): Observable<ShopInfo> {
    return this.http
      .get(`${environment.API_URL}/shops/${idNegozio}`)
      .pipe(map((data: ShopInfo) => data));
  }

  /* Richiesta HTTP .GET all'API SETUP, la quale restituisce array di oggetti NegozioSetup, il seguente array contiene dati come
    raggio, giorni massimi, spese di spedizione e spedizione gratuita dopo x limite */
  getSetup(): Observable<ShopSetup> {
    return this.http
      .get(`${environment.API_URL}/shops/setup`)
      .pipe(map((data: any) => data));
  }

  /* Richiesta HTTP .GET all'API GIORNI, la quale restituisce array di oggetti NegozioGiorni contenenti i giorni disponibili
    per la consegna a domicilio o takeaway (la tipologia dei giorni è definita dalla variabile
    'tipo' e può contenere solo due stati: TAKEAWAY o ASPORTO) */
  getHours(
    type: string
  ): Observable<BusinessHours[]> {
    return this.http
      .get(`${environment.API_URL}/shops/hours/`, {
        params: { type: type },
      })
      .pipe(map((data: BusinessHours[]) => data));
  }

  getShoppingDays(type: string): Observable<ShopDay[]> {
    return this.getSetup().pipe(
      switchMap(setup => this.getHours(type).pipe(
        map(days => this.buildDays(type, days, setup))
      ))
    );
  }

  buildHours(orari: string[], giorno: Date): Date[] {
    return orari
      .map((o) => {
        const hour = o.split(':')[0];
        const min = o.split(':')[1];
        const date = new Date(giorno);
        if (Number(hour) >= 24) {
          date.setDate(date.getDate() + 1);
          date.setHours(Number(hour) - 24, Number(min), 0, 0);
        } else {
          date.setHours(Number(hour), Number(min), 0, 0);
        }
        return date;
      })
      .sort((a, b) => a.valueOf() - b.valueOf());
  }

  buildDays(type: string, days: BusinessHours[], setup: ShopSetup): ShopDay[] {
    const dayIndiciesByDayName = Object.fromEntries(
      ['dom', 'lun', 'mar', 'mer', 'gio', 'ven', 'sab'].map((name, i) => [
        name,
        i,
      ])
    );
    const todayDayIndex = new Date().getDay();
    const todayDayOfMonth = new Date().getDate();
    const changeDate = (oreMinime, limiteOre) => {
      const currHours = curDate.getHours();
      if (limiteOre === '00:00:00') {
        curDate.setHours(currHours + oreMinime);
        return;
      }
      const [ore, minuti] = limiteOre.split(':').map(Number);
      if (
        currHours > ore ||
        (currHours === ore && curDate.getMinutes() > minuti)
      ) {
        curDate.setHours(48);
      } else {
        curDate.setHours(24);
      }
      curDate.setHours(0, 0, 0, 0);
    };

    const curDate = new Date();

    if (type === 'asporto' || type === 'takeaway') {
      const casedProp = type[0].toUpperCase() + type.slice(1);
      changeDate(
        setup['oreMinime' + casedProp],
        setup['limiteOre' + casedProp]
      );
    }

    const itemDates = days.map((g) => {
      const itemDateObj = new Date();
      const itemDayIndex = dayIndiciesByDayName[g.giorno.toLowerCase()];
      const itemDayDifferenceFromToday = (itemDayIndex - todayDayIndex + 7) % 7;
      itemDateObj.setDate(todayDayOfMonth + itemDayDifferenceFromToday);
      const orari = this.buildHours(g.orari, itemDateObj);
      return {
        id: g.id, giorno: itemDateObj, orari: orari.filter((o) => {
          return o > curDate;
        })
      };
    });

    return itemDates
      .sort((a, b) => a.giorno.valueOf() - b.giorno.valueOf())
      .filter((g) => {
        return g.orari.length;
      });
  }
}
