import { Directive, HostListener, input } from '@angular/core';

@Directive({
    selector: '[appHref]',
    standalone: false
})
export class HrefDirective {
  readonly href = input<string>(undefined);

  @HostListener('click', ['$event'])
  noop(event: MouseEvent): void {
    const href = this.href();
    if (href.length === 0 || href === '#') {
      event.preventDefault();
    }
  }
}
