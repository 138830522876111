<header class="section-header bg-white shadow-sm" [class.position-fixed]="!menu()" [class.sticky]="showCart">
  <section class="header-main">
    <div class="container">
      <div class="d-flex justify-content-between">
        <a href="" appHref class="brand-wrap" [routerLink]="redirect?.path">
          <img class="logo" src="assets/images/logo.svg" alt="Logo VisualOrder" />
        </a>
        <div class="d-flex">
          @if (showCart && !menu()) {
            <div class="widget-header" style="width: 48px;">
              <a [routerLink]="'./cart'">
                <mat-icon [matBadgeHidden]="count === 0" [matBadge]="count" matBadgeColor="warn">shopping_cart</mat-icon>
                <span class="icon-text">Carrello</span>
              </a>
            </div>
          }
        </div>
      </div>
    </div>
  </section>
  @if (isLoading) {
    <ngb-progressbar class="indeterminate" type="primary" [animated]="true" [value]="50" height=".2rem">
    </ngb-progressbar>
  }
</header>
@if (!menu()) {
  <div class="spacer"></div>
}
