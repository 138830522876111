import { Cart, CartService } from '../../../core/services/cart.service';
import { Component, OnInit, input, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { filter } from 'rxjs';

@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss'],
    standalone: false
})
export class TopBarComponent implements OnInit {
  private cartService = inject(CartService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);

  readonly menu = input<boolean>(false);

  public showCart = false;
  public isLoading = false;
  public type = null;

  redirect: any = null;

  get count(): number {
    return this.cartService
      .getItems()
      .products.reduce(
        (acc, plu) => acc + (plu.um !== 'PZ' ? 1 : Number(plu.qta) || 0),
        0
      );
  }

  ngOnInit(): void {
    this.handleRedirectPath();

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.handleRedirectPath();
      });
  }

  handleRedirectPath(): void {
    const childRoute = this.route.firstChild;
    if (childRoute && childRoute.snapshot.data.showCart) {
      this.showCart = childRoute.snapshot.data.showCart;
    } else {
      this.showCart = false;
    }

    if (childRoute && childRoute.snapshot.data.redirect) {
      this.redirect = childRoute.snapshot.data.redirect;

      if (this.redirect.path == null) {
        this.redirect.path = this.getRedirectPath();
      }
    } else {
      this.redirect = null;
    }
  }

  getRedirectPath(): string {
    let cart = JSON.parse(localStorage.getItem('VO_CART'));

    if (cart) {
      return `./${cart.type}`
    }

    return '';
  }

}
