@if (data.mobileType === 'android') {
  <mat-toolbar
    class="android-prompt"
    >
    <button mat-raised-button (click)="installPwa()">
      Aggiungi alla schermata home
    </button>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
}
@if (data.mobileType === 'ios') {
  <div class="ios-prompt">
    <button class="close-ios-inv" mat-button color="primary" (click)="close()">
      <mat-icon class="me-0">close</mat-icon>
    </button>
    <div>
      Per installare questa web app sul tuo dispositivo prememi l'icona del 'Menu'
      e successivamente schiaccia il bottone 'Aggiungi alla schermata Home'
    </div>
    <div>
      <img src="assets/images/ios_share.svg" />
      <mat-icon>navigate_next</mat-icon>
      <img src="assets/images/add_home_ios.svg" />
    </div>
  </div>
}
