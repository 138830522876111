import { CartService } from '../services/cart.service';
import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve
} from '@angular/router';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartResolverService implements Resolve<boolean> {
  private cartService = inject(CartService);

  resolve(route: ActivatedRouteSnapshot): any {
    const company = route.parent.params.company;
    const shopId = route.parent.params.shopId;
    const type = route.params.type;
    this.cartService.setCart(company, shopId.toString(), type);

    return of(route.data.displayCart === true);
  }
}
